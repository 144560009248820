import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ExpandableSection from '../components/expandablesection'

const faqs = [
  {
    heading: 'How do the EdgeForecaster price forecasts work?',
    content:
      'EdgeForecaster price forecasts are produced through a combination of artificial intelligence models, the results of which are combined to produce a price forecast. The combination of models comprise a Seasonal Autoregressive Integrated Moving Average (SARIMA) model, Long-Short Term Memory (LSTM) model, a boosted Regression Tree classification model and a Hidden Markov Model.',
  },
  {
    heading: 'What is included in my subscription?',
    content:
      'Each pricing plan has a range of inclusions which are identified under each heading on the Plans page of this website. ',
  },
  {
    heading: 'What if I want to cancel my subscription?',
    content:
      'You can cancel your EdgeForecaster subscription at any time and will retain access until the next billing cycle. Cancelling your subscription is as simple as clicking ‘Cancel my Subscription’ on your profile page or emailing admin@edgeforecaster.com',
  },
  {
    heading: 'Who created EdgeForecaster?',
    content:
      'The creators of EdgeForecaster have a combine 30yrs experience in financial markets. They developed a lot of the algorithms and strategies used in EdgeForecaster while working for quantitative hedge funds as analysts and traders.',
  },
  {
    heading: 'When will the platform be officially released?',
    content:
      'The web platform is currently in the final stages of testing, with fine tuning of artificial intelligence models and optimising data processing the main focus. The official release is set for the start of April 2022, once the EdgeForecaster Mobile app and platform capacity can be properly tested.  Lifetime subscribers will receive access to the web platform earlier as the final Beta test is conducted during the start of March 2022.',
  },
]

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class FAQsPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'FAQs'
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              {faqs.map((item, key) => {
                return (
                  <ExpandableSection
                    key={key}
                    heading={item.heading}
                    content={item.content}
                  />
                )
              })}
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(FAQsPage)
export const FAQsPageQuery = graphql`
  query FAQsPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
