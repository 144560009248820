import * as React from "react"
import Grid from '@material-ui/core/Grid';
import {HiOutlineChevronDown, HiOutlineChevronUp} from "react-icons/hi"

class ExpandableSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }        
        this.toggleexpansion = this.toggleexpansion.bind(this);
    }
    
    toggleexpansion = event => {        
        if(this.state.expanded) {
            this.setState({expanded: false})
        } else {
            this.setState({expanded: true})
        }
        
    }
  
    render() {
        return (
            <React.Fragment>        
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div className="expandablesection">
                            <Grid container spacing={0}>
                                <Grid item xs={11}>
                                    <h3 className="expandableheading">{this.props.heading}</h3>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className="expandablearrow" onClick={this.toggleexpansion}>
                                        {this.state.expanded ? 
                                            <HiOutlineChevronUp/>
                                        :
                                            <HiOutlineChevronDown/>
                                        }                                        
                                    </div>
                                </Grid>
                            </Grid>
                            {this.state.expanded ? 
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="expandablecontents">{this.props.content}</div>
                                    </Grid>
                                </Grid>
                            : <div />}
                        </div>
                    </Grid>
                </Grid>        
            </React.Fragment>
        
    )}
}

export default ExpandableSection